
    .goods-shoot-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
         .title{
            border-bottom: 2px solid #F1F5FF;
            padding-bottom: 15px;
        }
        .item-input {
            &.max {
                width: 475px;
            }

            &.medium {
                width: 250px;
            }

            &.mini {
                width: 150px;
            }

            &.sex {
                width: 130px;
            }

            &.tiny {
                width: 120px;
            }
        }
         .item-content {
               display: flex;
                align-items: center;
                margin-bottom: 16px;
                  .item-title {
                    width: 120px;
                    text-align: right;
                    margin-right: 10px;
                }
                
         }

        .goods-shoot-header {
            display: flex;
            justify-content: space-between;
            /*align-items: center;*/
            /*height: 58px;*/
            border-bottom: 2px solid #F1F5FF;
            span {
                color: #333;
                font-size: 16px;
            }
        }
    }
    .task-table {
        border: 1px solid #E5E5E5;
        margin-top: 16px;
        .table-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: #F6F6F6;
            border-bottom: 1px solid #EEEEEE;
            box-sizing: border-box;
            padding: 0 40px;
        }
        .upload {
            display: flex !important;
            align-items: center;
        }
        .text {
            display: inline-block;
            &:nth-child(1) {
                width: 150px;
            }
            &:nth-child(3) {
                width: 550px;
            }
        }
        .table-body {
            display: flex;
            justify-content: space-between;
            height: 70px;
            line-height: 70px;
            padding: 0 40px;
        }
    }
    .file-upload-btn {
        display: none;
    }
    .upload-text {
        // display: inline-block;
        flex: 1;
        width: 1%;
        color: #666;
        margin-left: 10px;
    }
    .is-plain {
        background: #FFF;
        border-color: #1CB064;
        color: #1CB064;
        border-radius: 2px;
    }
    .download-materials {
        color: #2461EF;
        cursor: pointer;
    }
    .active {
        color: #038bc6 !important;
        background:url("../../../../../assets/Slice.png") ;
        background-repeat:no-repeat;
        background-color: #00000054;
        background-position:center center;
        height: 119px;
       width: 214px;
        
    }


#divAdd {
    background-color: #ededed;
    /*width: 100%;*/
    /*min-width: 1200px;*/
    width: 1000px;
    margin: 0px auto;
}
 
.divAdd-con {
    margin: 0px auto;
    width: 1000px;
    overflow: auto;
    padding: 30px 0px;
}
 
.divAdd-con img {
    float: left;
}
 
.indexrt {
    margin: 0px 40px;
}
 
.divAddleft img {
    float: left;
    margin-bottom: 7px;
}
 
.divAddleft {
    float: left;
    display: inline;
    width: 370px;
}
 
.divAddrt {
    float: right;
    display: inline;
    margin-top: 7px;
}
 
.back_add {
    // background-color: #ededed;
}
 
.divAdd-con img {
    border: none;
}
 
 
a:focus,
a:hover {
    color: #23527c;
}
 
 
.threeImg {
    height: 158px;
    // background: #ededed;
    // border-bottom: 3px solid #4679B2;
    width: 1082px;
    position: relative;
}
 
.threeImg .Containt ul {
    margin: 0 auto;
    width: 2400px;
    position: absolute;
    left: 0px;
    cursor: pointer;
    height: 100%
}
 
.threeImg .Containt ul li {
    width: 215px;
    margin-right: 40px;
    margin-top: 10px;
    float: left;
      list-style-type:none;
}
 
.threeImg .Containt ul li img {
    height: 120px;
    width: 100%;
    border-radius: 10px;
  
}
 .del-icon {
   
        color: #FF0000;
        margin: 0 0 0 10px;
    }
 
.Containt {
    position: relative;
    width: 1000px;
    height: 130px;
    overflow: hidden;
    margin: 0 auto;
}
 
.iconleft {
    position: absolute;
   width: 25px;
    height: 21px;
    top: 10px;
    z-index: 99999;
       padding-top: 4px;
    background-color: #1AB163 ;
    vertical-align: middle;
    margin-top: 50px;
    border-radius: 20px;
}
 
.iconright {
    position: relative;
    left: 1038px;
    top: 70px;
    background-color: #1AB163 ;
    /*position: absolute;*/
    width: 25px;
    height: 21px;
    top: -120px;
    z-index: 99999;
       padding-top: 4px;
    vertical-align: middle;
    margin-top: 50px;
    border-radius: 20px;
}
